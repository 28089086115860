import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import { Link } from 'gatsby'
import classNames from 'classnames/bind'



type institutionalDeskType = {

}

const institutionalDesk: React.FC<institutionalDeskType> = () => {
   return (
      <div className={styles.institutional}>
         <h3 className={classNames(styles.h3, styles.textAlignStart)}>
            INSTITUCIONAL
               </h3>
         <ul className={styles.ul}>
            <Link to={'/'} className={styles.link}>
               <li>Quem somos</li>
            </Link>
            <Link to={'/'} className={styles.link}>
               <li>Fale conosco</li>
            </Link>
            <Link to={'/'} className={styles.link}>
               <li>Segurança</li>
            </Link>
            <Link to={'/'} className={styles.link}>
               <li>Política de privacidade</li>
            </Link>
            <Link to={'/'} className={styles.link}>
               <li>Pagamentos</li>
            </Link>
            <Link to={'/'} className={styles.link}>
               <li>Pagamentos</li>
            </Link>
            <Link to={'/'} className={styles.link}>
               <li>Prazos e formas de envio</li>
            </Link>
            <Link to={'/'} className={styles.link}>
               <li>Garantia e trocas</li>
            </Link>
            <Link to={'/'} className={styles.link}>
               <li>Política de devolução</li>
            </Link>
         </ul>
      </div>
   )
}

export default institutionalDesk