import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import SingleLazyImageComponent from '../../ImageComponent/SingleLazyImageComponent'
import { MAGENTO_URL } from '../../../../userglobals'

type paymentMethodsType = {
}

const paymentMethods: React.FC<paymentMethodsType> = () => {
   const imgUrl = `${MAGENTO_URL}media/icons/forma-de-pagamento.svg`

   return (
      <div className={styles.paymentMethods}>
         <span id="paymentMethodsDesk"> FORMAS DE PAGAMENTO </span>
         <div className={styles.paddingLeft}>
            <SingleLazyImageComponent imgUrl={imgUrl} altImg={'Formas de Pagamento'} widthContainer={343} heightContainer={26} widthLoading={20} heightLoading={20} />
         </div>
      </div>
   )
}

export default paymentMethods

