import React, { useState } from "react"
import { useSelector } from 'react-redux'
import styles from './DrawerCart.module.scss'
import { toggleDrawerRight, incrementProductQty, decrementProductQty, deleteFromCart } from '../../../../store/actions'
import Button from "@material-ui/core/Button"
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import CartItem from './components/cartItem'
import FooterCart from './components/footerCart'
import axios from 'axios'
import { MICROSERVICE_CART } from '../../../../../userglobals'
import ClassNames from 'classnames/bind'

let cx = ClassNames.bind(styles);

type DrawerCartType = {

}

const DrawerCart: React.FC<DrawerCartType> = () => {
  const cart = useSelector((state: any) => state.cart);
  const userAuth = useSelector((state: any) => state.userAuth);
  const totalPrice = useSelector((state: any) => state.totalPrice);
  console.log(cart)
  const [buttonCep, openButtonCep] = useState(false);
  const [buttonCupom, openButtonCupom] = useState(false);
  const [cep, setCep] = useState("");
  const [cupom, setCupom] = useState("");
  const [priceFretes, setPriceFretes] = useState({
    active: false,
    prices: []
  });
  const [freteLoading, setFreteLoading] = useState(false);
  console.log(totalPrice)
  const handleButtonCep = () => {
    openButtonCep(!buttonCep);
  }
  const handleButtonCupom = () => {
    openButtonCupom(true);
  }
  const handleCep = (cep: React.SetStateAction<string>) => {
    setCep(cep);
  }
  const handleCupom = (cupom: React.SetStateAction<string>) => {
    setCupom(cupom);
  }

  const handleAddQty = (idx: number, item: any) => {
    if (userAuth.auth !== false && userAuth.token !== "") {
      const copyCartItem = { ...item.magentoCartItem.cartItem }
      copyCartItem.qty++;
      axios.post(MICROSERVICE_CART + 'edit-customer-cart-item', { ...userAuth, cartItem: copyCartItem, itemId: item.magentoCartItem.cartItem.item_id })
        .then(response => {
          //console.log(response.data)
          incrementProductQty(idx)
        })
    } else {
      incrementProductQty(idx)
    }
  }
  const handleSubtractQty = (idx: number, item: any) => {
    if (userAuth.auth !== false && userAuth.token !== "") {

      const copyCartItem = { ...item.magentoCartItem.cartItem }
      copyCartItem.qty--;
      axios.post(MICROSERVICE_CART + 'edit-customer-cart-item', { ...userAuth, cartItem: copyCartItem, itemId: item.magentoCartItem.cartItem.item_id })
        .then(response => {
          //console.log(response.data)
          decrementProductQty(idx)
        })
    } else {
      decrementProductQty(idx)
    }
  }

  const handleDeleteItem = (idx: number, item: any) => {
    if (userAuth.auth !== false && userAuth.token !== "") {
      axios.post(MICROSERVICE_CART + 'delete-customer-cart-item', { ...userAuth, itemId: item.magentoCartItem.cartItem.item_id })
        .then(response => {
          //console.log(response.data)
          deleteFromCart(idx)
        })
    } else {
      deleteFromCart(idx)
    }
  }

  var qtyItems = 0;
  var subtotal = 0;

  cart.map((item: { visibleCartItem: { price: number; qty: React.ReactText } }) => {
    qtyItems = qtyItems + (+item.visibleCartItem.qty)
    subtotal = subtotal + (item.visibleCartItem.price * (+item.visibleCartItem.qty))
  })

  const ClassName = cx({
    cartItemsContainer: true,
    containerEmptyCart: cart.length === 0
  });

  return (
    <>
      <div className={styles.drawerCartMain}>
        {/* Menu principal (Sacola) */}
        <div className={styles.gridSacola}>
          <div className={styles.closeButton}>
            <Button
              onClick={toggleDrawerRight}
              disableTouchRipple
              color="primary"
              style={{ minWidth: "20px", height: "20px", padding: 0 }}
            >
              <CloseRoundedIcon fontSize="small" />
            </Button>
          </div>
          <div className={styles.menuPrincipal}>
            <span>SACOLA {qtyItems !== 0 && <>({qtyItems})</>}</span>
          </div>
          <div className={styles.closeButton}>
          </div>
        </div>
        {/* Items do carrinho */}
        <div className={ClassName}>
          {
            cart.length === 0 ?
              <div className={styles.divEmptyCart}>
                <LocalMallOutlinedIcon fontSize='large' /> <br />
                <b>Sua sacola está vazia!</b> <br />
                        Você ainda não adicionou nenhum produto à sacola. <br />
                <Button disableTouchRipple color='primary' onClick={toggleDrawerRight} className={styles.buttonKeepBuying}>
                  Clique aqui para <br />
                continuar comprando
                        </Button>
              </div> :
              <div style={{ width: '100%' }}>
                {
                  cart.map((item: any, idx: number) => (
                    <CartItem key={idx} idx={idx} item={item} handleAddQty={handleAddQty} handleSubtractQty={handleSubtractQty} handleDeleteItem={handleDeleteItem} />
                  ))
                }
              </div>
          }
        </div>
      </div>
      {
        cart.length !== 0 &&
        <FooterCart
        freteLoading={freteLoading}
        setFreteLoading={setFreteLoading}
        setPriceFretes={setPriceFretes}
        priceFretes={priceFretes}
        subtotal={subtotal}
        totalPrice={totalPrice}
        buttonCep={buttonCep}
        handleButtonCep={handleButtonCep}
        cep={cep} handleCep={handleCep}
        buttonCupom={buttonCupom}
        handleButtonCupom={handleButtonCupom}
        cupom={cupom}
        handleCupom={handleCupom}
      />
      }
    </>

  )
}

export default DrawerCart
