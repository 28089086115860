import React, { useState, ReactNode } from "react"
import Header from "../Headers/header"
import SimpleHeader from '../Headers/simpleHeader'
import Footer from "../Footers/Footer/Footer"
import FooterCheckout from '../Footers/FooterCheckout/FooterCheckout'
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import { useSelector } from 'react-redux'
import theme from "../../themes/defaultTheme"
import Container from "@material-ui/core/Container"
import GlobalLoading from '../Loadings/GlobalLoading'
import DrawerRight from '../Drawers/DrawerRight/DrawerRight'
import DrawerLeft from '../Drawers/DrawerLeft/DrawerLeft'
import LoginModal from '../Modals/LoginModal/LoginModal'
import Alert from '../Alert/Alert'


type LayoutType = {
   children: ReactNode
   location: Location
}

const Layout: React.FC<LayoutType> = ({ children, location }) => {

   const [layoutState, setLayoutState] = useState({
      isDrawerCategoryOpen: false,
      isDrawerSearchOpen: false,
      isDrawerRegisterOpen: false,
      loginIsVisible: false,
      isMounted: false,
   })
   const isLoading = useSelector((state: any) => state.isLoading);
   const isDrawerRightOpen = useSelector((state: any) => state.drawers.isDrawerRightOpen);
   const isDrawerLeftOpen = useSelector((state: any) => state.drawers.isDrawerLeftOpen);
   const isLoginOpen = useSelector((state: any) => state.modals.isLoginOpen);
   const alertMessage = useSelector((state: any) => state.alertMessage);
   const alertButton = useSelector((state: any) => state.alertButton);
   const isAlertOpen = useSelector((state: any) => state.isAlertOpen);
   //console.log(isLoading)
   const toggleLoginInToolbar = () => {
      setLayoutState(prevState => ({
         ...prevState,
         loginIsVisible: !layoutState.loginIsVisible,
      }))
   }

   if (location && location.pathname.match(/^\/checkout/)) {
      return (
         <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app">
               <div className="content">
                  {isLoading && <GlobalLoading isLoading={isLoading} />}
                  {isAlertOpen &&
                     <Alert isAlertOpen={isAlertOpen} message={alertMessage} button={alertButton} />}
                  <SimpleHeader />
                  <Container component="main">{children}</Container>
               </div>
               <FooterCheckout />
            </div>
         </MuiThemeProvider>
      )
   } else if (location && location.pathname.match(/^\/sucesso/) || location && location.pathname.match(/^\/cadastro/)) {
      return (
         <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app">
               <div className="content">
                  {isLoading && <GlobalLoading isLoading={isLoading} />}
                  {isAlertOpen &&
                     <Alert isAlertOpen={isAlertOpen} message={alertMessage} button={alertButton} />}
                  <SimpleHeader />
                  {/* {rastreioAlert && <CepAlert rastreioAlert={rastreioAlert} />} */}
                  <Container component="main">{children}</Container>
               </div>
            </div>
         </MuiThemeProvider>
      )
   } else {
      return (
         <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app">
               <div className="content">
                  <Header
                     loginIsVisible={layoutState.loginIsVisible}
                     toggleLoginInToolbar={toggleLoginInToolbar}
                  />
                  {
                     isLoginOpen
                     &&
                     <LoginModal isLoginOpen={isLoginOpen} />
                  }
                  {isAlertOpen &&
                     <Alert isAlertOpen={isAlertOpen} message={alertMessage} button={alertButton} />}
                  <GlobalLoading isLoading={isLoading} />
                  {isDrawerRightOpen &&
                     <DrawerRight />
                  }
                  {isDrawerLeftOpen &&
                     <DrawerLeft />
                  }

                  <Container component="main">{children}</Container>
               </div>
               <Footer />
            </div>
         </MuiThemeProvider>
      )
   }
}

export default Layout
