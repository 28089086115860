import React from "react"
import categoriesTree from "../../../data/categoriesTree.json"
import styles from "../Navbar.module.scss"
import CategoryElement from './categoryElement'
import SubcategoryDiv from './subcategoryDiv'
import Container from "@material-ui/core/Container"
import { MAGENTO_URL } from '../../../../userglobals'
import AliancasPanel from './AliancasPanel'

const categories = [
  "ALIANÇAS",
  "JOIAS",
  "KITS",
  "MOMENTOS",
  "PERSONALIZADAS",
  "COLEÇÕES",
  "PROMOÇÕES"
]


type categoriesPanelType = {
  panelOpen: number | null
  handleTogglePanel: (id: number) => void;
  setPanelOpen: React.Dispatch<React.SetStateAction<number | null>>
}

const categoriesPanel: React.FC<categoriesPanelType> = ({ panelOpen, handleTogglePanel, setPanelOpen }) => {
  return (
    <div className={styles.categoriesBar}>
      {/* {categories.map((category, index) => {
        return (<div key={index} onClick={() => { handleTogglePanel(index) }}>
          {category}
        </div>)
      })}
      <AliancasPanel panelOpen={panelOpen}/> */}
      {categoriesTree.map((category:any) => {
        return (
          <div key={category.id}>
            <CategoryElement
              category={category}
              handleTogglePanel={handleTogglePanel}
            />
            {category.children_data.length !== 0 && (
              <SubcategoryDiv
                category={category}
                Nodeid={category.id}
                childs={category.children_data}
                panelOpen={panelOpen}
                setPanelOpen={setPanelOpen}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default categoriesPanel
