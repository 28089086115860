import React, { useState } from 'react'
import LoginComponent from '../LoginComponent/LoginComponent'
import { useSelector } from 'react-redux'
import { signUser, loginAuthenticatedUser, validateEmail, verifyCustomer, setLoadingFalse, closeModalLogin, globalAlert } from '../../store/actions'
import { navigate } from 'gatsby'


type LoginType = {

}

const isLoggedIn = async (wishlist: any, cart: any) => {
  const isBrowser = () => typeof window !== "undefined"
  if (isBrowser() && window.localStorage.getItem("customerToken") && (typeof window.localStorage.getItem("customerToken") === "string")) {
    const tokenRes = JSON.parse(window.localStorage.getItem('customerToken'));
    if (tokenRes !== null) {
      verifyCustomer(tokenRes)
        .then(response => {
          if (response.auth) {
            loginAuthenticatedUser(tokenRes, wishlist, cart);
          } else {
            window.localStorage.removeItem('customerToken');
            setLoadingFalse();
          }
        })
    }
  }
}

const Login:React.FC<LoginType> = () => {
  const [loginState, setLoginState] = useState({
    activeStep: 0,
    email: 'danillo.santos@virtualjoias.com',
    password: '',
    showPassword: false
  });
  
  const cart = useSelector((state: any) => state.cart);
  const wishlist = useSelector((state: any) => state.wishlist);
  const loginUser = () => {
    signUser(loginState.email, loginState.password, wishlist, cart)
  }
  const handleChange = (label: any, value: any) => {

    const copyLoginState: any = { ...loginState };
    copyLoginState[label] = value;
    // console.log(newLoginState)
    setLoginState(copyLoginState)
  }
  const isEmailValid = async () => {
    const isEmailValid = await validateEmail(loginState.email);
    console.log(isEmailValid)
    if (!isEmailValid) {
      setLoginState(prevState => ({
        ...prevState,
        activeStep: 1
      })
      )
    } else {
      globalAlert('Esse email não está cadastrado em nosso sistema.', 'Criar conta');
    }

  }

  const forgotPassword = () => {
    setLoginState(prevState => ({
      ...prevState,
      activeStep: 2
    }))
  }

  const handleBack = () => {
    setLoginState(prevState => ({
      ...prevState,
      activeStep: 1
    }))
  }

  const handleClickShowPassword = () => {
    setLoginState({ ...loginState, showPassword: !loginState.showPassword });
  };

  isLoggedIn(wishlist, cart);
  return (
    <LoginComponent
      activeStep={loginState.activeStep}
      email={loginState.email}
      password={loginState.password}
      showPassword={loginState.showPassword}
      handleChange={handleChange}
      loginUser={loginUser}
      isEmailValid={isEmailValid}
      forgotPassword={forgotPassword}
      handleBack={handleBack}
      handleClickShowPassword={handleClickShowPassword}
    />
  )
}

export default Login
