import React, { useState } from "react"
import styles from './DrawerLeft.module.scss'
import { useSelector } from "react-redux"
import Drawer from "@material-ui/core/Drawer"
import { toggleDrawerLeft } from '../../../store/actions'
import DrawerMeasuringTechniques from './DrawerMeasuringTechniques/DrawerMeasuringTechniques'


type DrawerCategoryType = {}

const LeftDrawerSwitch: React.FC<any> = ({ drawerMeasuringTechniquesOpen }) => {
   if (drawerMeasuringTechniquesOpen) {
      return <DrawerMeasuringTechniques />
   } 
   return null
}

const DrawerCategory: React.FC<DrawerCategoryType> = () => {
   const drawerLeftOpen = useSelector((state: any) => state.drawers.isDrawerLeftOpen)
   const drawerMeasuringTechniquesOpen = useSelector((state: any) => state.drawers.isMeasuringTechniquesOpen)

   return (
      <Drawer
         anchor={"left"}
         open={drawerLeftOpen}
         onClose={toggleDrawerLeft}
         data-testid="drawer-category"
         classes={{
            paper: styles.mainContainer,
         }}
      >
         <LeftDrawerSwitch drawerMeasuringTechniquesOpen={drawerMeasuringTechniquesOpen} />
      </Drawer>
   )
}

export default DrawerCategory
