import React from 'react'
import styles from '../LoginComponent.module.scss'
import { ClassNamesFn } from 'classnames/types'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

type forgotPasswordType = {
   activeStep: number;
   handleBack: () => void
   cx: ClassNamesFn;
}

const forgotPassword: React.FC<forgotPasswordType> = ({ cx, activeStep, handleBack }) => {
   const className = cx({
      displayBlock: activeStep === 2,
      displayNone: activeStep !== 2
   });

   return (
      <div className={className} >
         <div style={{ textAlign: 'center',width:'100%' }}>
            <b style={{ fontSize: '16px' }}>Esqueceu sua senha?</b>
            <br />
            <span>Por favor, preencha seu email abaixo. <br />
            Você receberá um link para recuperar sua senha.</span>
            <TextField margin="dense" classes={{ root: "textfieldLogin" }} style={{ width: '90%', marginTop: '20px' }} label="Email" variant="outlined"></TextField>
            <br />
            <br />
            <div style={{display: 'flex', justifyContent: 'space-between', padding: '0 20px'}}>
               <Button onClick={() => handleBack()} color='primary' variant='outlined' size='large' classes={{ root: styles.buttonVoltar }}>Voltar</Button>
               <Button variant='contained' size='large' classes={{ root: styles.buttonEnviar }}>Enviar</Button>
            </div>
         </div>
      </div>
   )
}

export default forgotPassword
