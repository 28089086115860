import React from 'react'
import styles from "../Navbar.module.scss"
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { CategoryType } from 'types'
import { Link } from 'gatsby'

type SubcategoryDivType = {
   Nodeid: number;
   panelOpen: number | null;
   childs: CategoryType[];
   setPanelOpen: React.Dispatch<React.SetStateAction<number | null>>;
   category: CategoryType;
}


const recursive = (category: CategoryType, contador: number, fatherSlug: string) => {

   if (category.level === 3 && category.children_data.length !== 0) {

      return (
         <ul>
            <div key={category.id} className={styles.listCategoryUl}>{category.name}</div>

            {category.children_data.length < 5 && contador < 5 ?
               <>
                  {category.children_data.map(subcategory => {
                     contador++;
                     return (recursive(subcategory, contador, fatherSlug))
                  })}

               </>
               :
               <>
                  {category.children_data.map(subcategory => {
                     contador++;
                     if (contador <= 5) {
                        return (recursive(subcategory, contador, fatherSlug))
                     }
                  })}
                  <Link className={styles.resetLink} to={"/" + fatherSlug + "/"}>< li className={styles.listStyle} > Ver mais... </li></Link>
               </>
            }

         </ul >
      );
   } else {
      // Caso base
      return (<Link key={category.id} className={styles.resetLink} to={"/" + category.slug.toLowerCase() + "/"}><li className={styles.listStyle} >{category.name}</li></Link>)
   }
};


// {display:'none',position:'absolute',width:'100%',backgroundColor:'red',top:'110px',zIndex:99999,left:0}
const SubcategoryDiv: React.FC<SubcategoryDivType> = ({ category, Nodeid, panelOpen, childs, setPanelOpen }) => {
   const fatherSlug = category.slug;
   return (

      <div onMouseLeave={() => setPanelOpen(null)} className={styles.categoryDiv} style={(panelOpen === Nodeid) ? { display: 'block' } : { display: 'none' }}>
         <Container classes={{ root: styles.container2 }}  >

            <div className={styles.gridContainer} >
               <div className={styles.grid12}>
                  <div  className={styles.gridContainer}>
                     {childs.map((categoria: CategoryType) => {
                        let contador = 0;

                        return (<div key={categoria.id}  className={styles.gridMargin}>{recursive(categoria, contador, fatherSlug)}</div>)

                     })}

                  </div>
               </div>
               <div className={styles.verTudo} >
                  <Link className={styles.verTudo} to={"/" + category.slug + "/"}>Ver tudo</Link>
               </div>
            </div>

         </Container>

      </div>


   )
}

export default SubcategoryDiv
