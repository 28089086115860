import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import SingleLazyImageComponent from '../../ImageComponent/SingleLazyImageComponent'
import { MAGENTO_URL } from '../../../../userglobals'

type shipmentMethodsType = {

}

const shipmentMethods: React.FC<shipmentMethodsType> = () => {
   const imgUrl = `${MAGENTO_URL}media/icons/formas-de-envio.svg`

   return (
      <div className={styles.shipmentMethods}>
         <span id="shipmentMethodsDesk">FORMAS DE ENVIO</span>
         <div className={styles.shipmentDiv}>
            <SingleLazyImageComponent imgUrl={imgUrl} altImg={'Formas de Envio'} widthContainer={114} heightContainer={26} widthLoading={20} heightLoading={20} />
         </div>
      </div >
   )
}

export default shipmentMethods