export const MAGENTO_URL="https://magento-virtualjoias.com/";

const MICROSERVICES = "https://www.c8gnbkqwycz9hwwzc.com/"

export const SOCKET_NODES = MICROSERVICES;
export const API_BASE_URL= MICROSERVICES+"builder/";
export const MICROSERVICE_GET_CATEGORY_PRODUCTS= MICROSERVICES+"category-worker/";
export const MICROSERVICE_LOGIN = MICROSERVICES+"login-worker/";
export const MICROSERVICE_WISHLIST = MICROSERVICES+"wishlist-worker/";
export const MICROSERVICE_CART = MICROSERVICES+'cart-worker/';
export const MICROSERVICE_CHILD = MICROSERVICES+'child-worker/';
export const MICROSERVICE_CORREIOS = MICROSERVICES+'correios-worker/';
export const MICROSERVICE_USER = MICROSERVICES+'user-worker/';
export const MICROSERVICE_MERCADOPAGO = MICROSERVICES+'mercadopago-worker/';
export const MICROSERVICE_ORDER = MICROSERVICES+'order-worker/'
