import React from 'react'
import styles from '../Footer.module.scss'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container'

type FooterType = {

}

const Footer: React.FC<FooterType> = () => {

   return (
      <Container component={'footer'} className={styles.footerCheckout}>
         <LockOutlinedIcon />
            <span style={{paddingLeft: '10px'}}>Ambiente seguro</span>
      </Container>
   )
}

export default Footer
