import React from 'react'
import Backdrop from '@material-ui/core/Backdrop';
import styles from './LoginModal.module.scss'
import Login from '../../Login/Login'
import { closeModalLogin } from '../../../store/actions'
import Modal from '@material-ui/core/Modal';

type LoginModalType = {
  isLoginOpen: boolean
}

const LoginModal: React.FC<LoginModalType> = ({ isLoginOpen }) => {

  return (
    <Modal
      className={styles.loginModal}
      open={isLoginOpen}
      classes={{root:styles.backdrop}}
      onClose={closeModalLogin}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <><Login/></>
    </Modal>
  )
}

export default LoginModal
