import React, { useState } from 'react'
import styles from './DrawerMeasuringTechniques.module.scss'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import Button from '@material-ui/core/Button'
import { toggleDrawerLeft } from '../../../../store/actions'
import Printer from '@material-ui/icons/PrintOutlined'
import ImageComponent from '../../../ImageComponent/SingleImageComponent'
import { MAGENTO_URL } from '../../../../../userglobals'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Divider from '@material-ui/core/Divider'

type DrawerMeasuringTechniquesType = {

}

const DrawerMeasuringTechniques: React.FC<DrawerMeasuringTechniquesType> = () => {
   const [activeStep, setActiveStep] = useState(0);

   const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
   };

   const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
   };

   const steps = [
      {
         label: 'Medição com aneleira',
         text: 'A aneleira possui vários aros, de diversas numerações, para saber o seu número basta que você experimente alguns dos aros até descobrir o que melhor se encaixa em seu dedo. Está é a forma mais indicada para se descobrir o número do aro de sua aliança.<br/><br/> Qualquer joalheria possui um medidor como esse.<br/>',
         image: 'media/icons/aneleira.png'
      },
      {
         label: 'Medição com pau medidor',
         text: 'Outra boa forma de se descobrir o aro de sua aliança é utilizando um Pau Medidor de Anéis e Alianças, você deve inserir um anel ou aliança que utiliza no dedo que vai usar a sua nova aliança no medidor, ele então informara o seu número. (Lembramos que para alianças de compromisso, namoro e noivado o dedo onde a aliança é usada é o dedo da mão direita, alianças de casamento são usadas na mão esquerda). <br/><br/>Qualquer joalheria também possui um medidor como esse.<br/>',
         image: 'media/icons/pau-medidor.png'
      },
      {
         label: 'Descobrindo o diâmetro interno das alianças',
         text: 'Nesse método devemos medir somente a parte interna da aliança, em linha reta, de uma extremidade a outra, não medir as bordas, mas somente a extremidade interna. Após obter a medida você deve utilizar a tabeza abaixo para descobrir o tamanho da sua aliança.<br/>',
         image: 'media/icons/diametro-interno.svg'
      },
      {
         label: 'Medida com barbante / fita em volta do dedo',
         text: 'Também é possível medir o aro de seu dedo utilizando um barbante ou fita em volta do dedo, no entanto este é o método menos recomendado, pois é menos precioso o que pode gerar grandes variações.<br/><br/><b>1.</b> Para se descobrir o número do dedo onde você pretende utilizar sua aliança ou anel você deve dar uma volta na junta do seu dedo com um barbante, linha ou algum tipo de fio flexível. (É importante lembrar que a volta, e medida nesse caso deve ser feito pela junta, isto porque muitas pessoas possuem a parte final do dedo mais fina do que a junta, se este for o seu caso a aliança ficará pequena para o seu dedo e não passará em sua junta, por tanto muito cuidado).<br/><br/><b>2.</b> Após dar a volta com o fio em seu dedo amarre-o bem e então corte, estenda o fio sobre uma régua e verifique o comprimento informado, feito isso procure na tabela abaixo o número do aro correspondente à medida realizada. <br/>',
         label2: '<b>IMPORTANTE</b><br/>',
         text2: 'Salientamos que a variação das numerações é medida em milímetros, por isto qualquer pequeno erro pode gerar uma numeração errada, é importante que qualquer dos métodos que sejam utilizados sejam aplicados com muito cuidado para que sua aliança ou anel se encaixe como você deseja.<br/>Lembramos ainda que apesar de universais os medidores e aneleiras possuem variações de até um número para baixo ou para cima.<br/>Outra coisa que pode causar variação é o clima, em temperaturas baixas, ou seja, em dias frios, nossas mãos se contraem com isso nossa numeração diminuirá, já em dias quentes as mãos incham o que causa um aumento em sua numeração. Por isto escolha dias com temperaturas mais comuns em sua região para efetuar sua medição.',
         image: 'media/icons/medida-barbante.svg'
      },
      {
         label: 'Impressão de Aros',
         text: '<b>1.</b> Imprima o modelo abaixo com os tamanhos dos aros, para isso clique sobre a imagem com o número das alianças;<br/><b>2.</b> Pegue um anel que lhe sirva e que seja referente ao dedo que deseja usar a aliança;<br/><b>3.</b> Coloque o anel sobre os aros da folha impressa;<br/><b>4.</b> A parte de dentro do círculo do seu anel deverá ficar exatamente em cima do círculo interno do anel impresso, pnde as setas serão o limite;<br/><b>5.</b> Feito esse processo, é só ver o seu número correto de aro e nos enviar.<br/>',
         text2: 'Após imprimir a imagem com os aros é importante que você confira se o risco abaixo mede realmente 2 cm, se não tiver exatamente 2 cm é porque a ficha com os aros foi impressa de forma incorreta com a resolução menor ou maior, neste caso não use esse método.<br/><br/>Não coloque o anel ou aliança sobre o monitor, pois o tamanho que aparece retire em sua tela não coincide com o tamanho real, é necessário imprimir a imagem.<br/>Este é um método que possui chances de erro se não for feito corretamente, por isto tome muito cuidado, a informação correta de seu aro é de sua responsabilidade.'
      }
   ]
   const aros = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35];
   let medida = 4.90;
   return (
      <div className={styles.drawerSizesMain}>
         <div className={styles.gridSizes}>
            <div className={styles.closeButton}>
            </div>
            <div className={styles.menuPrincipal}>
               <span>DESCUBRA O TAMANHO DO SEU ARO</span>
            </div>
            <div className={styles.closeButton}>
               <Button
                  onClick={toggleDrawerLeft}
                  disableTouchRipple
                  color="primary"
                  style={{ minWidth: "20px", height: "20px", padding: 0 }}
               >
                  <CloseRoundedIcon fontSize="small" />
               </Button>
            </div>
         </div>
         <div className={styles.contentDiv}>
            <div className={styles.fixedText}>
               Para você que não sabe o número de sua aliança, seja ela de compromisso/namoro ou casamento/noivado existem cinco formas de se descobrir, a mais indicada é usando uma aneleira ou pau medidor, abaixo ensinamos você a descobrir o número do seu aro pelas cinco formas possíveis.
            </div>
            <div className={styles.divMethods}>
               <Button size="small" variant="outlined" color="primary" className={styles.buttons} onClick={handleBack} disabled={activeStep === 0} classes={{ disabled: styles.buttonDisabled }}>
                  Anterior
                  </Button>
                  MÉTODO {activeStep + 1}
               <Button size="small" variant="outlined" color="primary" className={styles.buttons} onClick={handleNext} disabled={activeStep === steps.length - 1} classes={{ disabled: styles.buttonDisabled }}>
                  Próximo
                  </Button>
            </div>
            <div className={styles.textAlignCenter}>
               <span className={styles.methodTitle}>{steps[activeStep].label}</span>
               {<p className={styles.text} dangerouslySetInnerHTML={{ __html: steps[activeStep].text }} />}
               {
                  activeStep === 4 &&
                  <Button
                     size='medium'
                     variant='text'
                  >
                     <Printer fontSize='large' color='primary' />
                     <span className={styles.printText}>
                        Clique aqui para
                           <br /> imprimir o modelo
                              </span>
                  </Button>
               }
               {
                  activeStep === 2 || activeStep === 3 ?
                     <Divider className={styles.dividerStyle} /> : null
               }
               {
                  steps[activeStep].image &&
                  <ImageComponent imgUrl={MAGENTO_URL + steps[activeStep].image + "?width=160&auto=webp"} altImg={MAGENTO_URL + steps[activeStep].label} />
               }
               {
                  activeStep === 2 || activeStep === 3 ?
                     <Divider className={styles.dividerStyle} /> : null
               }
               {
                  activeStep === 2 &&
                  <Table className={styles.table} aria-label="a dense table" size="small">
                     <TableHead>
                        <TableRow>
                           <TableCell className={styles.tableHead}>Tamanho da aliança</TableCell>
                           <TableCell className={styles.tableHead} align="right">Medida em mm <br /> do diâmetro</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 10 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 14,80mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 11 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 15,20mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 12 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 15,50mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 13 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 15,80mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 14 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 16,20mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 15 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 16,50mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 16 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 16,80mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 17 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 17,20mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 18 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 17,50mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 19 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 17,80mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 20 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 18,20mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 21 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 18,50mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 22 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 18,80mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 23 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 19,20mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 24 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 19,50mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 25 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 19,90mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 26 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 20,20mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 27 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 20,50mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 28 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 20,80mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 29 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 21,20mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 30 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 21,50mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 31 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 21,80mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 32 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 22,20mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 33 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 22,50mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 34 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 22,80mm </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell className={styles.tableCell} component="th" scope="row"> 35 </TableCell>
                           <TableCell className={styles.tableCell} align="left"> 23,20mm </TableCell>
                        </TableRow>
                     </TableBody>
                  </Table>
               }
               {
                  activeStep === 3 &&
                  <Table className={styles.table} aria-label="a dense table" size="small">
                     <TableHead>
                        <TableRow>
                           <TableCell className={styles.tableHead}>Tamanho da aliança</TableCell>
                           <TableCell className={styles.tableHead} align="right">Medida em cm<br /> do diâmetro</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {aros.map((aro, index) => {
                           medida = medida + 0.10;
                           return (
                              <TableRow key={index}>
                                 <TableCell className={styles.tableCell} component="th" scope="row"> {aro} </TableCell>
                                 <TableCell className={styles.tableCell} align="left"> {medida.toFixed(2).replace('.', ',')} cm</TableCell>
                              </TableRow>
                           )
                        })}
                     </TableBody>
                  </Table>
               }
               {
                  steps[activeStep].label2 &&
                  <p className={styles.label} dangerouslySetInnerHTML={{ __html: steps[activeStep].label2! }} />
               }
               {
                  steps[activeStep].text2 &&
                  <p className={styles.text} dangerouslySetInnerHTML={{ __html: steps[activeStep].text2! }} />
               }
            </div>
         </div>
      </div>
   )
}

export default DrawerMeasuringTechniques
