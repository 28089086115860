import { store } from "../../../wrap-with-provider"
import * as actionTypes from "./actionTypes"
import axios from "axios"
import {
  MICROSERVICE_LOGIN,
  MICROSERVICE_WISHLIST,
  MICROSERVICE_CART,
  MICROSERVICE_USER,
  MICROSERVICE_ORDER,
  MICROSERVICE_CORREIOS,
  // MICROSERVICE_MERCADOPAGO,
} from "../../../userglobals"
import { sendPaymentInfo } from "../../components/Checkout/utils/functions"
import { navigate } from "gatsby"

export const addToCart = ({ visibleCartItem, magentoCartItem }) => {
  const payload = {
    visibleCartItem,
    magentoCartItem,
  }
  store.dispatch({ type: actionTypes.ADD_TO_CART, payload })
}

export const toggleDrawerCart = () => {
  store.dispatch({ type: actionTypes.TOGGLE_DRAWER_CART })
}

export const toggleDrawerMeasuringTechniques = () => {
  store.dispatch({ type: actionTypes.TOGGLE_DRAWER_MEASURING_TECHNIQUES })
}

export const toggleDrawerLeft = () => {
  store.dispatch({ type: actionTypes.TOGGLE_DRAWER_LEFT })
}

export const toggleChangePassword = bool => {
  const payload = {
    changePasswordOpen: bool,
  }
  store.dispatch({ type: actionTypes.TOGGLE_CHANGE_PASSWORD, payload })
}

export const toggleEditAddress = bool => {
  const payload = {
    editAddressOpen: bool,
  }
  store.dispatch({ type: actionTypes.TOGGLE_EDIT_ADDRESS, payload })
}

export const setSelectedOrder = id => {
  const payload = {
    selectedOrder: id,
  }
  store.dispatch({ type: actionTypes.SET_SELECTED_ORDER, payload })
}

export const handleChangePassword = async (
  userAuth,
  currentPassword,
  newPassword,
  confirmPassword
) => {
  store.dispatch({ type: actionTypes.SET_LOADING_TRUE })
  try {
    await axios.post(MICROSERVICE_USER + "change-customer-password", {
      token: userAuth.token,
      currentPassword,
      newPassword,
      confirmPassword,
    })
    window.localStorage.removeItem("customerToken")
    //alerta
    globalAlert("Senha alterada com sucesso.")
    store.dispatch({ type: actionTypes.SET_LOADING_FALSE })
  } catch (err) {
    //alerta

    globalAlert("Erro ao alterar a senha.")
    store.dispatch({ type: actionTypes.SET_LOADING_FALSE })
  }
}

export const toggleDrawerRight = () => {
  store.dispatch({ type: actionTypes.TOGGLE_DRAWER_RIGHT })
}

export const openDrawerLogin = () => {
  const cart = store.getState().cart
  const wishlist = store.getState().wishlist
  const isBrowser = () => typeof window !== "undefined"
  if (
    isBrowser() &&
    window.localStorage.getItem("customerToken") &&
    typeof window.localStorage.getItem("customerToken") === "string"
  ) {
    const tokenRes = JSON.parse(window.localStorage.getItem("customerToken"))
    if (tokenRes !== null) {
      verifyCustomer(tokenRes).then(response => {
        if (response.auth) {
          loginAuthenticatedUser(tokenRes, wishlist, cart)
        } else {
          window.localStorage.removeItem("customerToken")
          store.dispatch({ type: actionTypes.OPEN_MODAL_LOGIN_USER })
        }
      })
    }
  } else {
    window.localStorage.removeItem("customerToken")
    store.dispatch({ type: actionTypes.OPEN_MODAL_LOGIN_USER })
  }
}

export const closeModalLogin = () => {
  console.log("ok")
  store.dispatch({ type: actionTypes.CLOSE_MODAL_LOGIN_USER })
}

export const handleSaveUserData = async (userAuth, userData) => {
  store.dispatch({ type: actionTypes.SET_LOADING_TRUE })
  return await axios
    .post(MICROSERVICE_USER + "edit-customer-data", {
      token: userAuth.token,
      customer: userData,
    })
    .then(response => {
      const payload = {
        userData: response.data,
      }
      store.dispatch({ type: actionTypes.SAVE_USER_DATA, payload })
    })
    .catch(err => {
      globalAlert("Erro ao editar informações de usuário.")
      store.dispatch({ type: actionTypes.OPEN_MODAL_LOGIN_USER })
    })
}

export const getCustomerLastOrder = async userAuth => {
  return await axios
    .post(MICROSERVICE_USER + "get-customer-last-order", {
      token: userAuth.token,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      globalAlert("Erro ao carregar ordens de pedido.")
    })
}

export const getCustomerOrders = async userAuth => {
  return await axios
    .post(MICROSERVICE_USER + "get-customer-orders", { token: userAuth.token })
    .then(response => {
      return response.data
    })
    .catch(err => {
      globalAlert("Erro ao carregar ordens de pedido.")
    })
}

export const loginAuthenticatedUser = async (tokenRes, wishlist, cart) => {
  store.dispatch({ type: actionTypes.SET_LOADING_TRUE })
  const userAuth = {
    auth: true,
    token: tokenRes.token,
  }

  try {
    const cartRes = await syncCart({ ...userAuth, cart })
    const wishlistRes = await getWishList({ ...userAuth, wishlist })
    const userDataRes = await getCustomerData({ ...userAuth })
    const payload = {
      userAuth,
      wishlist: wishlistRes,
      cart: cartRes.cartItems,
      quoteId: cartRes.quoteId,
      totalPrice: cartRes.totalPrice,
      userData: userDataRes,
    }
    store.dispatch({ type: actionTypes.LOGIN_USER_DRAWER, payload })
  } catch (err) {
    window.localStorage.removeItem("customerToken")
    store.dispatch({ type: actionTypes.SET_LOADING_FALSE })
  }
}

export const signUser = async (email, password, wishlist, cart) => {
  console.log(email)
  store.dispatch({ type: actionTypes.SET_LOADING_TRUE })
  const userAuthRes = await loginCustomer(email, password)
  if (userAuthRes !== 0) {
    const wishlistRes = await getWishList({ ...userAuthRes, wishlist })
    const cartRes = await syncCart({ ...userAuthRes, cart })
    const userDataRes = await getCustomerData({ ...userAuthRes })
    const payload = {
      userAuth: userAuthRes,
      wishlist: wishlistRes,
      cart: cartRes.cartItems,
      quoteId: cartRes.quoteId,
      totalPrice: cartRes.totalPrice,
      userData: userDataRes,
    }
    window.localStorage.setItem(
      "customerToken",
      JSON.stringify({ token: userAuthRes.token })
    )
    store.dispatch({ type: actionTypes.LOGIN_USER_DRAWER, payload })
  } else {
    store.dispatch({ type: actionTypes.SET_LOADING_FALSE })
    globalAlert("Senha incorreta.")
  }
}

export const logout = () => {
  window.localStorage.removeItem("customerToken")
  store.dispatch({ type: actionTypes.LOGOUT })
}

export const createCustomer = async (customer, password, confirmPassword) => {
  console.log(customer)

  await axios
    .post(MICROSERVICE_USER + "create-customer", {
      customer,
      password,
      confirmPassword,
    })
    .then(res => {
      globalAlert("Cadastrado com sucesso.")
      navigate("/")
    })
    .catch(err => {
      console.log(err.response.data.message)
      globalAlert(err.response.data.message)
    })
}

export const verifyCustomer = async token => {
  return axios
    .post(MICROSERVICE_LOGIN + "verify-customer-token", { token: token.token })
    .then(response => {
      return response.data
    })
}

export const loginCustomer = async (email, password) => {
  //console.log({username:email,password:password})
  return axios
    .post(MICROSERVICE_LOGIN + "login-customer/", {
      username: email,
      password: password,
    })
    .then(response => {
      // //console.log(response.data)
      if (response.data !== "Erro") {
        return response.data
      } else {
        store.dispatch({ type: actionTypes.SET_LOADING_FALSE })
        return 0
      }
    })
}

export const changeCustomerData = newUserData => {
  //console.log(newUserData)
  const payload = {
    userData: newUserData,
  }
  store.dispatch({ type: actionTypes.CHANGE_CUSTOMER_DATA, payload })
}

export const validateEmail = async email => {
  const response = await axios.post(MICROSERVICE_LOGIN + "check-email/", {
    customerEmail: email,
  })

  return response.data
}

export const getWishList = async ({ auth, token, wishlist }) => {
  return axios
    .post(MICROSERVICE_WISHLIST + "get-wishlist", {
      auth: auth,
      token: token,
      wishlist: wishlist,
    })
    .then(response => {
      return response.data
    })
}

export const syncCart = async ({ auth, token, cart }) => {
  return axios
    .post(MICROSERVICE_CART + "sync-cart", { auth, token, cart })
    .then(response => {
      // console.log(response.data)
      return response.data
    })
    .catch(err => {
      //console.log(err)
    })
}

export const getCustomerData = async ({ auth, token }) => {
  return axios
    .post(MICROSERVICE_USER + "get-customer-data", { auth, token })
    .then(response => {
      return response.data
    })
}

export const setLoadingTrue = async () => {
  await store.dispatch({ type: actionTypes.SET_LOADING_TRUE })
}

export const setLoadingFalse = () => {
  store.dispatch({ type: actionTypes.SET_LOADING_FALSE })
}

export const setBilletUrl = billet => {
  const payload = {
    billet,
  }
  store.dispatch({ type: actionTypes.SET_BILLET_URL, payload })
}

export const toggleLoginAlert = bool => {
  const payload = {
    loginAlert: bool,
  }
  // console.log(payload)
  store.dispatch({ type: actionTypes.TOGGLE_LOGIN_ALERT, payload })
}

export const addToWishlist = id => {
  const payload = id
  console.log(id)
  store.dispatch({ type: actionTypes.ADD_TO_WISHLIST, payload })
}

export const removeFromWishlist = id => {
  const payload = id
  store.dispatch({ type: actionTypes.REMOVE_FROM_WISHLIST, payload })
}

export const incrementProductQty = idx => {
  const payload = {
    idx,
  }
  store.dispatch({ type: actionTypes.INCREMENT_PRODUCT_QTY, payload })
}

export const decrementProductQty = idx => {
  const payload = {
    idx,
  }
  store.dispatch({ type: actionTypes.DECREMENT_PRODUCT_QTY, payload })
}

export const deleteFromCart = idx => {
  const payload = {
    idx,
  }
  store.dispatch({ type: actionTypes.DELETE_ITEM_FROM_CART, payload })
}

export const subscribeToNewsletter = async (userData, userAuth) => {
  store.dispatch({ type: actionTypes.SET_LOADING_TRUE })
  return axios
    .post(MICROSERVICE_USER + "newsletter-subscribe", { token: userAuth.token })
    .then(response => {
      console.log(response.data)
      userData.extension_attributes.is_subscribed = true
      const payload = {
        userData: userData,
      }

      store.dispatch({ type: actionTypes.SAVE_USER_DATA, payload })
    })
}

export const unsubscribeToNewsletter = async (userData, userAuth) => {
  store.dispatch({ type: actionTypes.SET_LOADING_TRUE })
  return axios
    .post(MICROSERVICE_USER + "newsletter-unsubscribe", {
      token: userAuth.token,
    })
    .then(response => {
      console.log(response.data)
      userData.extension_attributes.is_subscribed = false
      const payload = {
        userData,
      }
      store.dispatch({ type: actionTypes.SAVE_USER_DATA, payload })
    })
}

export const purchase = async (
  userAuth,
  billingAddress,
  shipmentAddress,
  paymentMethodSelect,
  userData,
  creditCardPaymentInfo,
  billetPaymentInfo,
  shippingInfo,
  cart
) => {
  setLoadingTrue();
  const newShipmentAddress = {
    region: shipmentAddress.region.region,
    region_id: shipmentAddress.region.region_id,
    region_code: shipmentAddress.region.region_code,
    country_id: shipmentAddress.country_id,
    street: shipmentAddress.street,
    postcode: shipmentAddress.postcode.replace(/[^\w\s]/gi, ""),
    city: shipmentAddress.city,
    firstname: shipmentAddress.firstname,
    lastname: shipmentAddress.lastname,
    email: userData.email,
    telephone: shipmentAddress.telephone,
  }
  const newBillingAddress = {
    region: billingAddress.region.region,
    region_id: billingAddress.region.region_id,
    region_code: billingAddress.region.region_code,
    country_id: billingAddress.country_id,
    street: billingAddress.street,
    postcode: billingAddress.postcode.replace(/[^\w\s]/gi, ""),
    city: billingAddress.city,
    firstname: billingAddress.firstname,
    lastname: billingAddress.lastname,
    email: userData.email,
    telephone: billingAddress.telephone,
  }
  const addressInformation = {
    shipping_address: newShipmentAddress,
    billing_address: newBillingAddress,
    shipping_carrier_code: "flatrate",
    shipping_method_code: "flatrate",
  }
  const paymentMethod = {
    method: paymentMethodSelect,
  }
  let responseShippingInformation = null;
  let responsePaymentInformation = null;
  let responseOrderComment = null;
  let responseMercadoPago = null;
  let responseNewQuoteId = null;
  let responseOrderCanceled = null;

  try {
    //Adiciona os dados de envio a ordem do magento
    responseShippingInformation = (await axios.post(MICROSERVICE_ORDER + "add-shipping-information", { ...userAuth, addressInformation })).data;
    console.log('Dados de envio colocados');
    console.log(responseShippingInformation);
    //Adiciona dados de pagamento a ordem do magento
    responsePaymentInformation = (await axios.post(MICROSERVICE_ORDER + "add-payment-information", { ...userAuth, paymentMethod })).data;
    console.log('Dados de pagamento colocados');
    console.log(responsePaymentInformation);
  } catch (err) {
    responsePaymentInformation = null;
    globalAlert("Erro ao finalizar pedido.");
  }

  if (responsePaymentInformation !== null) {
    //Adiciona um comentário com dados de envio a ordem recem criada
    try {
      responseOrderComment = (await axios.post(MICROSERVICE_ORDER + "add-comment-order-pendente", {
        token: userAuth.token, orderId: responsePaymentInformation.replace("OK", "").replace(/[^\w\s]/gi, ''),
        shippingInformation: {
          shippingPrice: shippingInfo?.Valor,
          shippingTime: shippingInfo?.PrazoEntrega,
          shippingService: shippingInfo?.servico,
        },
      })).data
      console.log('Comentário na ordem adicionado com sucesso');
    } catch (err) {
      //alert("Erro ao adicionar comentário a ordem de pedido");
    }
    //Gera o pagamento no mercado pago
    try {
      responseMercadoPago = (await sendPaymentInfo(creditCardPaymentInfo, paymentMethod.method, billetPaymentInfo));
      console.log('Ordem feita com sucesso')
    } catch (err) {
      console.log(err)
      try {
        responseOrderCanceled = (await axios.post(MICROSERVICE_ORDER + "add-comment-order-canceled", {
          token: userAuth.token, orderId: responsePaymentInformation.replace("OK", "").replace(/[^\w\s]/gi, ''),
          messageInformation: {
            message: err.message
          },
        })).data
        responseMercadoPago = null;
        console.log('Ordem cancelada com sucesso');
      } catch (err) {
        //alert("Erro ao adicionar comentário a ordem de pedido");
      }
    }

    try {
      // Cria novo carrinho para o usuário
      responseNewQuoteId = (await axios.post(MICROSERVICE_CART + "create-customer-cart", { ...userAuth, })).data
      const payload = {
        quoteId: responseNewQuoteId,
        successOrderNumber: responsePaymentInformation.replace("OK", "").replace(/[^\w\s]/gi, ''),
      }
      store.dispatch({
        type: actionTypes.CREATE_NEW_CART,
        payload,
      });
      const script = document.createElement("script");
      script.src = "https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js";
      script.id = "payMercadoPago";
      document.body.appendChild(script);
      script.onload = () => { };
      if (responseMercadoPago !== null) {
        await navigate("/sucesso/", { replace: true });
      } else {
        await navigate("/fracasso/", { replace: true });
      }
    } catch (err) {
      alert("Erro ao criar novo carrinho de usuário");
    }
  }

  setLoadingFalse();

  // console.log(userData);
  // console.log(cart);
  // if (sendPaymentInfo(creditCardPaymentInfo, paymentMethodSelect, billetPaymentInfo)) {
  //   const newShipmentAddress = {
  //     region: shipmentAddress.region.region,
  //     region_id: shipmentAddress.region.region_id,
  //     region_code: shipmentAddress.region.region_code,
  //     country_id: shipmentAddress.country_id,
  //     street: shipmentAddress.street,
  //     postcode: shipmentAddress.postcode.replace(/[^\w\s]/gi, ""),
  //     city: shipmentAddress.city,
  //     firstname: shipmentAddress.firstname,
  //     lastname: shipmentAddress.lastname,
  //     email: userData.email,
  //     telephone: shipmentAddress.telephone,
  //   }
  //   const newBillingAddress = {
  //     region: billingAddress.region.region,
  //     region_id: billingAddress.region.region_id,
  //     region_code: billingAddress.region.region_code,
  //     country_id: billingAddress.country_id,
  //     street: billingAddress.street,
  //     postcode: billingAddress.postcode.replace(/[^\w\s]/gi, ""),
  //     city: billingAddress.city,
  //     firstname: billingAddress.firstname,
  //     lastname: billingAddress.lastname,
  //     email: userData.email,
  //     telephone: billingAddress.telephone,
  //   }

  //   const addressInformation = {
  //     shipping_address: newShipmentAddress,
  //     billing_address: newBillingAddress,
  //     shipping_carrier_code: "flatrate",
  //     shipping_method_code: "flatrate",
  //   }
  //   console.log(MICROSERVICE_ORDER)
  //   return axios
  //     .post(MICROSERVICE_ORDER + "add-shipping-information", {
  //       ...userAuth,
  //       addressInformation,
  //     })
  //     .then(response => {
  //       console.log(response.data)
  // const paymentMethod = {
  //   method: paymentMethodSelect,
  // }
  //       axios
  //         .post(MICROSERVICE_ORDER + "add-payment-information", {
  //           ...userAuth,
  //           paymentMethod,
  //         })
  //         .then(response2 => {
  //           axios
  //             .post(MICROSERVICE_ORDER + "add-comment-order", {
  //               token: userAuth.token,
  //               orderId: response2.data.replace("OK", "").replace(/[^\w\s]/gi, ''),
  //               shippingInformation: {
  //                 shippingPrice: shippingInfo?.Valor,
  //                 shippingTime: shippingInfo?.PrazoEntrega,
  //                 shippingService: shippingInfo?.servico,
  //               },
  //             })
  //             .then(response => {
  //               axios
  //                 .post(MICROSERVICE_CART + "create-customer-cart", {
  //                   ...userAuth,
  //                 })
  //                 .then(async response3 => {
  //                   setQuoteId(response3.data)

  //                   const payload = {
  //                     quoteId: response3.data,
  //                     successOrderNumber: response2.data,
  //                   }
  //                   store.dispatch({
  //                     type: actionTypes.CREATE_NEW_CART,
  //                     payload,
  //                   })
  //                   await navigate("/sucesso/", { replace: true })
  //                 })
  //                 .catch(err => {
  //                   alert("Erro ao recriar carrinho de usuário")
  //                 })
  //             })
  //             .catch(err => {
  //               console.log(err.response)
  //               alert(err)
  //             })
  //         })
  //         .catch(err => {
  //           console.log(err.response)
  //           alert("Erro ao adicionar informações de pagamento")
  //         })
  //     })
  //     .catch(err => {
  //       alert("Erro ao adicionar informações de entrega")
  //     })
  // }

}

export const consultValidCep = async cep => {
  console.log(cep)
  const response = await axios.post(MICROSERVICE_CORREIOS + "check-cep", {
    cep,
  })
  if (response.data.Erro === 404 || response.data.erro === true) {
    console.log("error")
    return null
  } else {
    console.log("ok")
    return response.data
  }
}

export const closeAlert = () => {
  store.dispatch({ type: actionTypes.CLOSE_ALERT })
}

export const globalAlert = (message, button) => {
  const payload = {
    message: message,
    button: button
  }
  store.dispatch({ type: actionTypes.GLOBAL_ALERT, payload })
}