import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import styles from './Alert.module.scss'
import { closeAlert, globalAlert, closeModalLogin } from '../../store/actions'
import { navigate } from 'gatsby'

type AlertType = {
  isAlertOpen: boolean,
  message: string,
  button?: string
}

const Alert: React.FC<AlertType> = ({ isAlertOpen, message, button }) => {
  return (
    <Dialog
      open={isAlertOpen}
      onClose={() => closeAlert()}
      classes={{ paper: styles.alertDialog }}
    >
      <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <DialogContentText id="alert-dialog-description" style={{ fontSize: '14px' }}>
          {message || "Alerta de erro"}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ paddingBottom: '12px', display: 'flex', justifyContent: 'center' }}>
        {
          button ?
            (
              <Button className={styles.buttonConfirm} onClick={() => {
                switch (button) {
                  case 'Criar conta':
                    closeAlert();
                    navigate('/cadastro/', {});
                    closeModalLogin();
                    break;
                  default:
                    closeAlert()
                    break;
                }
              }} variant="contained" disableTouchRipple style={{ textTransform: 'none', letterSpacing: '1px' }}>
                {button}
              </Button>
            )
            :
            <Button className={styles.buttonConfirm} onClick={() => closeAlert()} variant="contained" disableTouchRipple>
              OK
            </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default Alert
