import React from 'react'
import Backdrop from '@material-ui/core/Backdrop';

import styles from './LoadingComponent.module.scss'
import GlobalLoadingSVG from '../../images/globalloading.inline.svg'

type GlobalLoadingType = {
    isLoading: boolean
}

const GlobalLoading: React.FC<GlobalLoadingType> = ({ isLoading }) => {

    // console.log(isLoading)
    return (
        <Backdrop classes={{ root: styles.backdrop }} open={isLoading}>
            <div style={{ width: '130px', height: '80px' }}>
                <GlobalLoadingSVG/>
            </div>
            
        </Backdrop>
    )
}

export default GlobalLoading