import React from 'react'
import styles from '../Footer.module.scss'
import Container from '@material-ui/core/Container'

import {
   ContactUs,
   Doubts,
   Institutional,
   MyAccount,
   Copyright,
   PaymentMethods,
   SecureWebsite,
   ShipmentMethods,
   News
} from '../components'

type FooterDeskType = {

}

const FooterDesk: React.FC<FooterDeskType> = () => {
   return (
      <Container className={styles.footer} >
         <ContactUs />
         <News nameId={'emailMobile'} />
         <Institutional />
         <Doubts />
         <MyAccount />
         <PaymentMethods />
         <ShipmentMethods />
         <SecureWebsite />
         <Copyright />
      </Container>
   )
}

export default FooterDesk
