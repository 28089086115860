import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import { Link } from 'gatsby'
import classNames from 'classnames/bind'

type doubtsDeskType = {

}

const doubtsDesk: React.FC<doubtsDeskType> = () => {
   return (
      <div className={styles.doubts}>
         <h3 className={classNames(styles.h3, styles.textAlignStart)}>
            DÚVIDAS
            </h3>
         <ul className={styles.ul}>
            <Link to={'/'} className={styles.link}>
               <li>Como comprar</li>
            </Link>
            <Link to={'/'} className={styles.link}>
               <li>Saiba o tamanho da sua aliança</li>
            </Link>
            <Link to={'/'} className={styles.link}>
               <li>O que gravar nas alianças</li>
            </Link>
            <Link to={'/'} className={styles.link}>
               <li>Emblemas anéis de formatura</li>
            </Link>
            <Link to={'/'} className={styles.link}>
               <li>Central de ajuda</li>
            </Link>
         </ul>
      </div>
   )
}

export default doubtsDesk