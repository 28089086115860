import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import { Link } from 'gatsby'
import classNames from 'classnames/bind'
import { setSelectedOrder } from '../../../store/actions'

type myAccountDeskType = {

}

const myAccountDesk: React.FC<myAccountDeskType> = () => {

   return (
            <div className={styles.myAccount}>
               <h3 className={classNames(styles.h3, styles.textAlignStart)}>
                  MINHA CONTA
            </h3>
               <ul className={styles.ul}>
                  <Link onClick={() => setSelectedOrder(null)} to={'/minha-conta/'} className={styles.link}>
                     <li>Meu painel</li>
                  </Link>
                  <Link to={'/minha-conta/pedidos/'} className={styles.link}>
                     <li>Meus pedidos</li>
                  </Link>
                  <Link to={'/minha-conta/enderecos/'} className={styles.link}>
                     <li>Meus endereços</li>
                  </Link>
               </ul>
            </div>
   )
}

export default myAccountDesk