import React from "react"
import '../Footer.scss'
import styles from '../Footer.module.scss'
import SingleLazyImageComponent from '../../ImageComponent/SingleLazyImageComponent'
import { MAGENTO_URL } from '../../../../userglobals'

type secureWebsiteType = {
}

const secureWebsite: React.FC<secureWebsiteType> = () => {
   const siteSeguro = `${MAGENTO_URL}media/icons/google-siteseguro.svg`
   const siteBlindado = `${MAGENTO_URL}media/icons/site-blindado.svg`
   const empresaIndicada = `${MAGENTO_URL}/media/icons/selo_reclameaqui.svg`
   const lojaConfiavel = `${MAGENTO_URL}media/icons/yourviews.svg`

   return (
      <div className={styles.secureWebsite}>
         <div className={styles.divImages}>
            <SingleLazyImageComponent
               imgUrl={empresaIndicada}
               altImg={"Empresa Indicada"}
               widthContainer={122}
               heightContainer={29}
               widthLoading={20}
               heightLoading={20}
               _class={styles.ImageSecureWebsite128}
            />
            <SingleLazyImageComponent
               imgUrl={siteBlindado}
               altImg={"Site Blindado"}
               widthContainer={122}
               heightContainer={29}
               widthLoading={20}
               heightLoading={20}
               _class={styles.ImageSecureWebsite128}
            />
            <SingleLazyImageComponent
               imgUrl={siteSeguro}
               altImg={"Site Seguro"}
               widthContainer={122}
               heightContainer={29}
               widthLoading={20}
               heightLoading={20}
               _class={styles.ImageSecureWebsite128}
            />
            <SingleLazyImageComponent
               imgUrl={lojaConfiavel}
               altImg={"Loja Confiável"}
               widthContainer={122}
               heightContainer={29}
               widthLoading={20}
               heightLoading={20}
               _class={styles.ImageSecureWebsite64}
            />
         </div>
      </div>
   )


}

export default secureWebsite
