import React from 'react'
import '../Footer.scss'
import styles from '../Footer.module.scss'
import SingleLazyImageComponent from '../../ImageComponent/SingleLazyImageComponent'
import { MAGENTO_URL } from '../../../../userglobals'
import classNames from 'classnames/bind'

type copyrightType = {
}

const copyright: React.FC<copyrightType> = () => {
   const logo = `${MAGENTO_URL}media/icons/logonova.png`;

   const copyrightText = "Todos os direitos reservados. <br> Proibida reprodução total ou parcial. <br> Preços e estoque sujeitos a alteração sem aviso prévio. <br> Virtual Joias Ltda - CNPJ: 10.194.987/0001-86 <br>";

   return (
      <div className={styles.copyright}>
         <div className={classNames(styles.maxWidth, styles.alignSelfCenter, styles.margin)}>
            <SingleLazyImageComponent imgUrl={logo} altImg={'Virtual Joias'} widthContainer={100} heightContainer={44} widthLoading={20} heightLoading={20} />
         </div>
         <div className={styles.alignCopyright}>
            <div>{copyrightText.replace(/\<br\>/g, " ")}</div>
         </div>
      </div>
   )
}

export default copyright
