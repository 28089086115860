import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import styles from "./Navbar.module.scss"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import { Link, navigate } from "gatsby"
import LogoNavbarVJ from "../../images/logoVj.inline.svg"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/Search"
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined"
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined"
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined"
import InputAdornment from "@material-ui/core/InputAdornment"
import Badge from "@material-ui/core/Badge"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { toggleDrawerRight, logout, openDrawerLogin, setSelectedOrder } from "../../store/actions"
import CategoriesPanel from './components/categoriesPanel'
import Popper from "@material-ui/core/Popper"
import { SOCKET_NODES, MICROSERVICE_GET_CATEGORY_PRODUCTS } from '../../../userglobals'
import io from "socket.io-client"
import SimpleImageComponent from '../../components/ImageComponent/SingleImageComponent'
import axios from 'axios'

let socket: SocketIOClient.Socket;

type NavbarType = {
  loginIsVisible: boolean
  toggleLoginInToolbar: () => void
}

const Navbar: React.FC<NavbarType> = ({
  loginIsVisible,
  toggleLoginInToolbar,
}) => {
  const userData = useSelector((state: any) => state.userData);
  const userAuth = useSelector((state: any) => state.userAuth);
  const cart = useSelector((state: any) => state.cart);
  const [menuAccount, setMenuAccount] = useState<null | HTMLElement>(null);
  const [menuHelp, setMenuHelp] = useState<null | HTMLElement>(null);
  const [panelOpen, setPanelOpen] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [categoryResponseList, setCategoryResponseList] = useState([]);
  const [productsResponseList, setProductsResponseList] = useState([]);
  const [isSearchActive, setSearchActive] = useState(false);

  let count = 0;


  useEffect(() => {
    socket = io(SOCKET_NODES, { transports: ['websocket'] });
  }, []);

  cart.map((item: any) => {
    count = count + (+item.visibleCartItem.qty)
  });

  const handleSearch = (value: string) => {

    setSearchValue(value)
    if (value.length >= 3) {
      socket.emit('search-input', { searchInput: value, productNumber: 4 }, ({ categoriesSuggested, productsSuggested }) => {
        setCategoryResponseList(
          categoriesSuggested.categorySuggestions[0].options
        )
        setProductsResponseList(productsSuggested)
      });
    } else {
      setCategoryResponseList([]);
      setProductsResponseList([]);
    }
  }

  const handleMenuAccountOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuHelp(null);
    setMenuAccount(event.currentTarget);
  }

  const handleMenuAccountClose = () => {
    setMenuAccount(null);
  }

  const handleMenuHelp = (event: any) => {
    if (menuHelp !== null) {
      setMenuHelp(null);
    } else {
      setMenuHelp(event.currentTarget);
    }
  }

  const handleTogglePanel = (id: number) => {
    if (panelOpen === id) {
      setPanelOpen(null);
    } else {
      setPanelOpen(id)
    }
  }

  const redirectToSearchpage = async () => {
    try {
      const response = await axios.post(MICROSERVICE_GET_CATEGORY_PRODUCTS + 'get-full-search-result', { searchInput: searchValue });
      navigate('/busca/', {
        state: { products: response.data, searchInput: searchValue },
      })
    } catch (err) {
      console.log(err)
    }
  }
  
  return (
    <AppBar
      position="static"
      color="secondary"
      style={{ boxShadow: "none" }}
    >
      <Toolbar classes={{ root: styles.toolbar, regular: styles.regular }}>
        <Container className={styles.container}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className={styles.logoWrapper}>
              <Link to="/" aria-label="Home Page" aria-current="page" rel="Go to home page">
                <LogoNavbarVJ fill="#e8927c" alt="Logomarca da Virtual Joias" />
              </Link>
            </div>

            <ClickAwayListener onClickAway={() => setSearchActive(false)}>
              <div className={styles.searchWrapper} >
                <TextField
                  placeholder="O que você procura?"
                  id="input-with-icon-textfield"
                  fullWidth
                  value={searchValue}
                  onFocus={() => setSearchActive(true)}
                  autoComplete="off"
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{

                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          className={styles.buttonSearch}
                          edge="start"
                          aria-label="search"
                          data-testid="button-toggle-collabse-toolbar"
                          onClick={() => {
                            if(productsResponseList.length > 0){
                              redirectToSearchpage()
                            }else if(categoryResponseList.length > 0){
                              navigate(`${categoryResponseList[0]._source.slug}/`)
                            }
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {isSearchActive && (categoryResponseList.length > 0 || productsResponseList.length > 0) &&
                  <div className={styles.searchDiv}>
                    {categoryResponseList.map((item: any, idx) => {
                      return (
                        <div onClick={() => {
                          setSearchActive(false);
                          navigate(`${item._source.slug}`)
                        }} className={styles.categorySuggestions} key={idx}>
                          {item._source.name}</div>
                      )
                    })}
                    {
                      productsResponseList.length > 0 &&
                      <>
                        <div className={styles.subtitleProductSuggest}> SUGESTÃO DE PRODUTOS</div>
                        <div className={styles.divProductSuggest}>
                          {productsResponseList.map((item: any, idx) => {
                            return (
                              <div key={idx} className={styles.productSuggestion} onClick={() => {
                                setSearchActive(false);
                                navigate(`/${item._source.slug}`)
                              }}>
                                <div style={{ marginBottom: '2px' }}>
                                  <SimpleImageComponent
                                    imgUrl={"https://magento-virtualjoias.com/media/catalog/product" + item._source.image}
                                    altImg={item._source.name}
                                  />
                                </div>
                                <div className={styles.cardBody}>
                                  <span className={styles.productName}>{item._source.name}</span>
                                  <div>
                                    <span className={styles.productPrice}>DE: R$ {item._source.price.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</span> <br />
                                    <span className={styles.productDiscountPrice}>Por: R$ {item._source.discount_price.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</span>
                                  </div>
                                </div>
                              </div>);
                          })}
                        </div>
                        <div onClick={redirectToSearchpage} style={{ display: 'flex', backgroundColor: 'white', padding: '8px', justifyContent: 'center', color: 'rgb(232, 146, 124)', cursor: 'pointer' }}> VER TODOS OS PRODUTOS</div>
                      </>
                    }
                  </div>
                }
              </div>
            </ClickAwayListener>

            <IconButton
              className={styles.button}
              edge="start"
              aria-label="login"
              onClick={handleMenuHelp}
            >
              <HelpOutlineOutlinedIcon />
            </IconButton>
            <Popper open={Boolean(menuHelp)} anchorEl={menuHelp} placement={'bottom'} onMouseLeave={(e) => handleMenuHelp(e)} >
              <div className={styles.menuHelp}>
                <div className={styles.divMenuHelp}>
                  <div className={styles.divContato}>
                    <h3 className={styles.phoneNumber}>(11) 3522-4808</h3>
                  </div>
                        De Seg. à Sex. das 8h às 18h <br />
                        sac@virtualjoias.com
                  </div>
                <MenuItem
                  className={styles.menuHelpItem}
                  component={Link}
                  to={"/"}
                  onClick={(e: any) => handleMenuHelp(e)}
                >
                  Como comprar
                </MenuItem>
                <MenuItem
                  className={styles.menuHelpItem}
                  component={Link}
                  to={"/"}
                  onClick={(e: any) => handleMenuHelp(e)}
                >
                  Saiba o tamanho de sua aliança
                </MenuItem>
                <MenuItem
                  className={styles.menuHelpItem}
                  component={Link}
                  to={"/"}
                  onClick={(e: any) => handleMenuHelp(e)}
                >
                  O que gravar nas alianças
                </MenuItem>
                <MenuItem
                  className={styles.menuHelpItem}
                  component={Link}
                  to={"/"}
                  onClick={(e: any) => handleMenuHelp(e)}
                >
                  Emblemas anéis de formatura
                </MenuItem>
                <MenuItem
                  className={styles.menuHelpItem}
                  component={Link}
                  to={"/"}
                  onClick={(e: any) => handleMenuHelp(e)}
                >
                  Central  de ajuda
                </MenuItem>
              </div>
            </Popper>

            <div className={styles.signInLoginDiv}>
              <div
                style={
                  !userAuth.auth
                    ? { display: "flex", alignItems: "center" }
                    : { display: "none" }
                }
              >
                <ExitToAppOutlinedIcon />
                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px', lineHeight: 'normal', fontSize: '13px' }}>
                  <div onClick={openDrawerLogin} style={{ cursor: 'pointer' }}><strong><u className={styles.hover}>Entrar</u></strong> ou</div>
                  <div onClick={() => navigate('/cadastro/')} style={{ cursor: 'pointer' }} className={styles.hover}><u>Criar conta</u></div>
                </div>
              </div>
              <div
                style={
                  userAuth.auth
                    ? { display: "inherit" }
                    : { display: "none" }
                }
              >
                <Button
                  disableTouchRipple
                  className={styles.button}
                  data-testid="button-toggle-collabse-toolbar"
                  onClick={handleMenuAccountOpen}
                >
                  <span style={{ textTransform: "none" }}>
                    Olá,{" "}
                    <b style={{ textTransform: "uppercase" }}>
                      {userData.firstname}
                    </b>
                  </span>
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={menuAccount}
                  keepMounted
                  open={Boolean(menuAccount)}
                  onClose={handleMenuAccountClose}
                  classes={{ paper: styles.menu }}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem
                    className={styles.menuItem}
                    component={Link}
                    to={"/minha-conta/"}
                    onClick={handleMenuAccountClose}
                  >
                    Minha conta
                  </MenuItem>
                  <MenuItem
                    className={styles.menuItem}
                    component={Link}
                    to={"/minha-conta/pedidos"}
                    onClick={() => { handleMenuAccountClose(); setSelectedOrder(null); }}
                  >
                    Meus pedidos
                  </MenuItem>
                  <MenuItem
                    className={styles.menuItem}
                    component={Link}
                    to={"/minha-conta/enderecos"}
                    onClick={handleMenuAccountClose}
                  >
                    Meus endereços
                  </MenuItem>
                  <MenuItem
                    className={styles.lastMenuItem}
                    onClick={() => {
                      logout();
                      handleMenuAccountClose();
                      navigate('/');
                    }}
                  >
                    SAIR
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <IconButton
              className={styles.button}
              edge="start"
              aria-label="cart"
              data-testid="button-toggle-cart-drawer"
              onClick={toggleDrawerRight}
            >
              <Badge
                badgeContent={count}
                color="primary"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                classes={{ anchorOriginBottomRightCircle: styles.badge }}
                overlap="circle"
                showZero
              >
                <LocalMallOutlinedIcon />
              </Badge>
            </IconButton>
          </div>
        </Container>
        <Container
          className={styles.container}
        >
          <CategoriesPanel
            panelOpen={panelOpen}
            handleTogglePanel={handleTogglePanel}
            setPanelOpen={setPanelOpen}
          />
        </Container>
      </Toolbar>
    </AppBar >
  )
}

export default Navbar
