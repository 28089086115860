import React from 'react'
import styles from './Navbar.module.scss'
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Container from "@material-ui/core/Container"
import { Link } from "gatsby"
import LogoNavbarVJ from "../../images/logoVj.inline.svg"

type NavbarCheckoutType = {
}

const NavbarCheckout: React.FC<NavbarCheckoutType> = () => {
   return (
      <AppBar position="static" color="secondary" style={{ boxShadow: 'none' }}>
         <Toolbar style={{ padding: '5px' }} classes={{ root: styles.toolbar }}>
            <Container className={styles.containerCheckout}>
               <div style={{ display: 'flex', justifyContent: 'center', maxWidth: '50%' }} className={styles.logoCheckout}>
                  <Link to="/" aria-label="Home Page" aria-current="page" rel="Go to home page">
                     <LogoNavbarVJ fill="#e8927c" alt="Logomarca da Virtual Joias" />
                  </Link>
               </div>
            </Container>
         </Toolbar >
      </AppBar >
   )
}

export default NavbarCheckout