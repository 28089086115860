import React from 'react'
import { CategoryType } from 'types'
import styles from "../Navbar.module.scss"
import { Link } from 'gatsby'

type CategoryElementType = {
   category: CategoryType;
   handleTogglePanel: (id: number) => void
}


const categoryElement: React.FC<CategoryElementType> = ({ category, handleTogglePanel }) => {
   if (category.children_data.length !== 0) {
      return (<div
         onClick={() => { handleTogglePanel(category.id) }}
         className={styles.categoriesBarItems}
      >
         {category.name}
      </div>)
   } else {
      return (
         < Link
            to={"/" + category.slug + "/"}
            className={styles.categoriesBarItems}
         >
            {category.name}
         </Link>)
   }
}

export default categoryElement
